@media only screen and (max-width: 767px) {
  .collapsed-menu-header {
    display: block;
    width: 25px;
    float: right;
    line-height: 76px;
    font-size: 24px;
    color: #ffffff;
    margin: 0 20px; }
  menu .menu ul.user-menu {
    float: right; }
  menu .menu ul.nav-menu {
    width: 200px;
    clear: both;
    float: right;
    background: #003a57;
    position: absolute;
    top: 76px;
    right: 0; }
    menu .menu ul.nav-menu li.menu-item {
      border-left: 0;
      display: block; }
      menu .menu ul.nav-menu li.menu-item a {
        text-align: left; }
        menu .menu ul.nav-menu li.menu-item a span {
          display: inline-block;
          width: 40px;
          height: auto;
          font-size: 16px;
          text-align: left; }
      menu .menu ul.nav-menu li.menu-item.user-menu-item {
        padding-top: 12px; }
      menu .menu ul.nav-menu li.menu-item .submenu-dropdown {
        position: relative;
        top: 0;
        width: 100%; }
        menu .menu ul.nav-menu li.menu-item .submenu-dropdown ul {
          width: 100%;
          float: none; }
          menu .menu ul.nav-menu li.menu-item .submenu-dropdown ul li {
            background: rgba(0, 0, 0, 0.25); }
            menu .menu ul.nav-menu li.menu-item .submenu-dropdown ul li a span {
              background: transparent; }
  .white-box .row-in-br {
    border: 0;
    margin-bottom: 15px; } }

@media only screen and (min-width: 768px) and (max-width: 1225px) {
  .menu-bar {
    height: 153px; }
    .menu-bar .menu {
      float: none;
      position: relative; }
      .menu-bar .menu .user-menu {
        float: right !important; }
        .menu-bar .menu .user-menu li.menu-item.user-menu-item {
          padding-top: 12px; }
      .menu-bar .menu .default_logo {
        position: absolute; }
      .menu-bar .menu .menu-items {
        margin: 0 0 0 auto;
        width: 100%; }
      .menu-bar .menu .nav-menu {
        clear: both;
        float: none;
        display: block;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
        text-align: right; }
        .menu-bar .menu .nav-menu li.menu-item:first-child {
          border-left: 0 solid rgba(255, 255, 255, 0.1); }
  .dashboard-filter {
    margin-top: 0; }
    .dashboard-filter .custom-label {
      min-width: 100px; }
    .dashboard-filter .filter-icon {
      display: none; }
    .dashboard-filter .filter-content {
      height: auto;
      overflow: hidden; }
      .dashboard-filter .filter-content .team-search {
        margin-top: 10px; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .white-box {
    padding-bottom: 10px; }
    .white-box .row-in .row-in-br {
      margin-bottom: 15px; }
    .white-box .row-in .b-r-none {
      border: 0; } }

@media only screen and (min-width: 1170px) {
  .menu-items {
    float: right;
    display: block; } }

@media only screen and (max-width: 1600px) {
  .setup-wizard .setup-container .setup-content .basic-container .basic-content .attractive {
    font-size: 42px;
    padding-top: 10px; }
  .setup-wizard .setup-container .setup-content .basic-container .basic-content h1 {
    font-size: 24px; }
  .setup-wizard .setup-container .setup-content .basic-container .basic-content h4 {
    font-size: 16px; }
  .setup-wizard .setup-container .setup-content .process-container .employee-icon {
    width: 60px;
    height: 60px; }
    .setup-wizard .setup-container .setup-content .process-container .employee-icon .attractive {
      font-size: 42px;
      line-height: 60px; }
  .setup-wizard .setup-container .setup-content .process-container .employee-name {
    font-size: 21px;
    margin-bottom: 12px; }
  .setup-wizard .setup-container .setup-content .process-container .endpoint-count,
  .setup-wizard .setup-container .setup-content .process-container .department-name,
  .setup-wizard .setup-container .setup-content .process-container .team-name,
  .setup-wizard .setup-container .setup-content .process-container .tag-name {
    text-align: left;
    font-size: 14px;
    margin-bottom: 12px; } }

@media only screen and (min-width: 1367px) and (max-width: 1600px) {
  .department-list .hex .hexIn h2 {
    font-size: 1.3em;
    top: 40%; }
  .department-list .hex .hexIn h3 {
    font-size: 3.5em;
    top: 42%; } }

@media only screen and (max-width: 1366px) {
  .department-list .hex .hexIn h2 {
    font-size: 1em;
    top: 40%; }
  .department-list .hex .hexIn h3 {
    font-size: 3em;
    top: 41%; } }

/* SPACING AND SIZING */
@media (min-width: 1201px) {
  .hex {
    width: 16.65%;
    /* = (100-4) / 5 */
    padding-bottom: 19.22%;
    /* =  width / sin(60deg) */ }
  .hex:nth-child(9n+7),
  .hex:nth-child(9n+8),
  .hex:nth-child(9n+9),
  .hex:nth-child(9n+10),
  .hex:nth-child(9n+11) {
    margin-top: -4.78%;
    margin-bottom: -4.78%;
    -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -ms-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    transform: translateX(50%) rotate(-60deg) skewY(30deg); }
  .hex:nth-child(9n+7):last-child,
  .hex:nth-child(9n+8):last-child,
  .hex:nth-child(9n+9):last-child,
  .hex:nth-child(9n+10):last-child,
  .hex:nth-child(9n+11):last-child {
    margin-bottom: 0; }
  .hex:nth-child(9n+7) {
    margin-left: 0;
    clear: left; }
  .hex:nth-child(9n+3) {
    margin: 0; }
  .hex:nth-child(9n+12) {
    margin: 0;
    clear: left; }
  .hex:nth-child(9n+4),
  .hex:nth-child(9n+5) {
    margin: 0; } }
