/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */
@media only screen and (max-width: 768px) {
  .mobile-nav .navbar-header .navbar-toggle .icon-bar {
    background-color: #ffffff; }
  .mobile-nav .navbar-header .user-menu {
    padding: 10px 15px 0; }
    .mobile-nav .navbar-header .user-menu .user-name {
      text-align: right; }
      .mobile-nav .navbar-header .user-menu .user-name a {
        padding: 0;
        margin-bottom: 2px;
        cursor: pointer;
        text-align: right;
        color: #43c3f1; }
    .mobile-nav .navbar-header .user-menu .user-company select {
      color: #ffffff; }
  .mobile-nav .navbar-collapse .nav {
    background-color: #003a57;
    margin: 7.5px -16px;
    max-height: calc(100vh - 150px);
    overflow-y: auto; }
    .mobile-nav .navbar-collapse .nav a {
      color: #ffffff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      position: relative;
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      text-align: left;
      padding: 0; }
      .mobile-nav .navbar-collapse .nav a:hover {
        background-position: 0 -100%;
        background-size: 100% 200%;
        background-image: linear-gradient(to bottom, #003a57 50%, #01283c 50%);
        -webkit-transition: background-position 500ms;
        -moz-transition: background-position 500ms;
        transition: background-position 500ms; }
      .mobile-nav .navbar-collapse .nav a:active {
        background-image: linear-gradient(to bottom, #003a57 50%, #01283c 100%); }
      .mobile-nav .navbar-collapse .nav a:focus {
        background-image: linear-gradient(to bottom, #003a57 50%, #01283c 100%); }
      .mobile-nav .navbar-collapse .nav a .fa {
        width: 35px;
        height: 35px;
        border-radius: 52%;
        text-align: center;
        line-height: 35px;
        background: #000000;
        margin-right: 10px;
        margin-bottom: 5px; }
    .mobile-nav .navbar-collapse .nav .open a {
      background-color: transparent; }
      .mobile-nav .navbar-collapse .nav .open a:focus {
        background-color: transparent; }
    .mobile-nav .navbar-collapse .nav .dropdown .dropdown-item:hover {
      background-position: 0 -100%;
      background-size: 100% 200%;
      background-image: linear-gradient(to bottom, #003a57 50%, #01283c 50%);
      -webkit-transition: background-position 500ms;
      -moz-transition: background-position 500ms;
      transition: background-position 500ms; }
  .employee-activity .basic-activity {
    position: static !important; }
    .employee-activity .basic-activity .infographic-container {
      text-align: center; }
      .employee-activity .basic-activity .infographic-container .page-sub-title {
        min-width: 100%; }
    .employee-activity .basic-activity .range-slider {
      min-height: 100px; }
  #alert-responsive-id .alert-summary-title-block {
    position: static;
    height: auto;
    min-height: 170px; }
    #alert-responsive-id .alert-summary-title-block .alert-title {
      margin-bottom: 20px; }
  #alert-responsive-id .alert-summary-content .row-in {
    display: block; }
    #alert-responsive-id .alert-summary-content .row-in .summary-box {
      display: block;
      width: 100%; } }

@media only screen and (max-width: 460px) {
  .mobile-nav .user-menu {
    float: none; }
    .mobile-nav .user-menu .user-name {
      text-align: left; }
    .mobile-nav .user-menu .user-company select {
      width: 68px; } }

@media only screen and (max-width: 1366px) {
  .pie-grid-item .label.percent-label {
    font-size: 14px; } }

@media (min-width: 768px) {
  .profile-container {
    width: 750px; } }

@media (min-width: 992px) {
  .profile-container {
    width: 970px; } }

@media (min-width: 1200px) {
  .profile-container {
    width: 1170px; } }

@media screen and (max-width: 1244px) and (min-width: 1171px) {
  .ai-custom-width-department .radials li {
    max-width: 365px !important; }
  .ai-custom-width-classification .radials li {
    max-width: 375px !important; } }

@media only screen and (min-width: 1589px) {
  .category-chart-count {
    min-height: 300px; } }

@media (min-width: 1120px) and (max-width: 1220px) {
  .alert-summary-content .summary-box .col-in {
    display: block; }
    .alert-summary-content .summary-box .col-in .col-last {
      float: none;
      width: 100%; }
      .alert-summary-content .summary-box .col-in .col-last h3 {
        text-align: center; }
    .alert-summary-content .summary-box .col-in .col-middle {
      width: 100% !important; }
      .alert-summary-content .summary-box .col-in .col-middle h4 {
        text-align: center; } }

@media (max-width: 1991px) {
  .navbar-left, .navbar-right {
    float: none !important; }
  .navbar-toggle-custom-display {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px; }
  html .mobile-custom-navbar .navbar-collapse.collapse {
    display: none; }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .collapse.in {
    display: block !important; } }

.menu-bar {
  padding: 0;
  height: 76px;
  margin: 0;
  background: #003a57;
  position: relative;
  z-index: 999; }
  .menu-bar app-header {
    margin: 0;
    padding: 0; }
    .menu-bar app-header .menu {
      color: #666666;
      padding: 0;
      float: right;
      color: #ffffff;
      width: 100%; }
      .menu-bar app-header .menu .default_logo {
        float: left;
        color: #ffffff;
        font-size: 20px;
        padding: 18px 5px 18px 0;
        display: block;
        height: 76px; }
        .menu-bar app-header .menu .default_logo .trademark img {
          height: auto;
          margin: 0 0 0 10px; }
      .menu-bar app-header .menu .menu-items {
        float: right;
        display: block; }
        .menu-bar app-header .menu .menu-items .user-menu {
          float: left; }
          .menu-bar app-header .menu .menu-items .user-menu .user-menu-item {
            min-height: 70px;
            padding: 18px 15px 0;
            color: #ffffff;
            text-align: right;
            font-size: 14px;
            border: 0;
            float: left;
            font-weight: 300; }
            .menu-bar app-header .menu .menu-items .user-menu .user-menu-item .user-name a {
              padding: 0;
              margin-bottom: 2px;
              cursor: pointer;
              text-align: right;
              color: #43c3f1; }
              .menu-bar app-header .menu .menu-items .user-menu .user-menu-item .user-name a:hover {
                color: #ffffff; }
            .menu-bar app-header .menu .menu-items .user-menu .user-menu-item:hover {
              background: transparent; }
          .menu-bar app-header .menu .menu-items .user-menu .user-block {
            padding: 0; }
            .menu-bar app-header .menu .menu-items .user-menu .user-block .user-name {
              font-size: 13px;
              text-transform: uppercase; }
            .menu-bar app-header .menu .menu-items .user-menu .user-block .user-company {
              font-size: 12px;
              text-transform: uppercase; }
        .menu-bar app-header .menu .menu-items .nav-menu .menu-item {
          display: inline-block;
          position: relative;
          border-left: 1px solid rgba(255, 255, 255, 0.1);
          background-size: 100% 200%;
          background-image: linear-gradient(to bottom, #003a57 50%, #01283c 50%);
          -webkit-transition: background-position 500ms;
          -moz-transition: background-position 500ms;
          transition: background-position 500ms; }
          .menu-bar app-header .menu .menu-items .nav-menu .menu-item a {
            padding: 15px 15px 10px;
            text-decoration: none;
            display: block;
            text-align: center;
            margin: 0;
            cursor: pointer;
            font-size: 12px;
            text-transform: uppercase;
            color: #ffffff; }
            .menu-bar app-header .menu .menu-items .nav-menu .menu-item a span {
              width: 100%;
              height: 30px;
              display: block;
              text-align: center;
              padding-right: 0;
              font-size: 26px;
              color: #ffffff;
              margin-bottom: 6px; }
          .menu-bar app-header .menu .menu-items .nav-menu .menu-item .submenu-dropdown {
            display: none;
            position: absolute;
            width: 197px;
            max-height: calc(100vh - 85px);
            overflow: auto;
            height: auto;
            z-index: 9999;
            top: 76px;
            right: 0;
            color: #ffffff;
            background: #01283c; }
            .menu-bar app-header .menu .menu-items .nav-menu .menu-item .submenu-dropdown ul {
              min-width: 180px;
              width: 100%; }
              .menu-bar app-header .menu .menu-items .nav-menu .menu-item .submenu-dropdown ul li.submenu-item {
                display: block;
                clear: both;
                height: auto;
                line-height: 1;
                padding: 0;
                background-size: 100% 200%;
                background-image: linear-gradient(to bottom, #003a57 50%, #01283c 50%);
                -webkit-transition: background-position 500ms;
                -moz-transition: background-position 500ms;
                transition: background-position 500ms; }
                .menu-bar app-header .menu .menu-items .nav-menu .menu-item .submenu-dropdown ul li.submenu-item a {
                  line-height: 42px;
                  font-size: 12px;
                  text-align: left;
                  padding: 0;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
                  .menu-bar app-header .menu .menu-items .nav-menu .menu-item .submenu-dropdown ul li.submenu-item a span {
                    width: 42px;
                    height: 42px;
                    display: inline-block;
                    text-align: center;
                    line-height: 42px;
                    padding: 0;
                    overflow: hidden;
                    background: #000000;
                    margin-right: 10px;
                    font-size: 20px;
                    float: left;
                    margin-bottom: 0; }
                .menu-bar app-header .menu .menu-items .nav-menu .menu-item .submenu-dropdown ul li.submenu-item:hover {
                  background-position: 0 -100%; }
          .menu-bar app-header .menu .menu-items .nav-menu .menu-item:hover {
            background-position: 0 -100%; }
            .menu-bar app-header .menu .menu-items .nav-menu .menu-item:hover .submenu-dropdown {
              display: block; }
        .menu-bar app-header .menu .menu-items .nav-menu li:nth-child(3) .submenu-dropdown {
          width: 285px; }
    .menu-bar app-header ul {
      margin: 0;
      padding: 0;
      list-style: none;
      float: right; }

.employee-activity .basic-activity {
  background: #ededed;
  padding: 10px 0;
  border-bottom: 2px solid #ffffff;
  height: auto;
  overflow: hidden;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 500; }
  .employee-activity .basic-activity h4 {
    margin: 0;
    display: block;
    clear: both;
    float: none;
    letter-spacing: 1px; }
  .employee-activity .basic-activity .user-details .infographic-container, .employee-activity .basic-activity .users-activity-counts .infographic-container {
    display: table;
    position: relative;
    height: 100%;
    margin: 0 auto;
    min-height: 100px; }
    .employee-activity .basic-activity .user-details .infographic-container .infographic-container-inner, .employee-activity .basic-activity .users-activity-counts .infographic-container .infographic-container-inner {
      position: relative;
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      word-break: break-word; }
      .employee-activity .basic-activity .user-details .infographic-container .infographic-container-inner h1, .employee-activity .basic-activity .users-activity-counts .infographic-container .infographic-container-inner h1 {
        padding: 0 0 0 15px;
        margin: 0;
        display: block;
        clear: both;
        float: none;
        height: auto;
        line-height: 100%;
        margin-bottom: 5px; }
      .employee-activity .basic-activity .user-details .infographic-container .infographic-container-inner .page-title, .employee-activity .basic-activity .users-activity-counts .infographic-container .infographic-container-inner .page-title {
        font-family: 'Oxygen', sans-serif;
        font-size: 21px;
        font-weight: 400;
        color: #003a57;
        background: #ededed; }
      .employee-activity .basic-activity .user-details .infographic-container .infographic-container-inner .page-sub-title, .employee-activity .basic-activity .users-activity-counts .infographic-container .infographic-container-inner .page-sub-title {
        padding-left: 14px;
        width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        .employee-activity .basic-activity .user-details .infographic-container .infographic-container-inner .page-sub-title .employee-dept, .employee-activity .basic-activity .users-activity-counts .infographic-container .infographic-container-inner .page-sub-title .employee-dept {
          font-size: 14px; }
        .employee-activity .basic-activity .user-details .infographic-container .infographic-container-inner .page-sub-title span, .employee-activity .basic-activity .users-activity-counts .infographic-container .infographic-container-inner .page-sub-title span {
          font-size: 12px;
          padding-left: 3px; }
      .employee-activity .basic-activity .user-details .infographic-container .infographic-container-inner .skills-text-overflow, .employee-activity .basic-activity .users-activity-counts .infographic-container .infographic-container-inner .skills-text-overflow {
        padding-left: 14px;
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .employee-activity .basic-activity .user-details .infographic-container .infographic-container-inner .activity-overview-block, .employee-activity .basic-activity .users-activity-counts .infographic-container .infographic-container-inner .activity-overview-block {
        height: auto;
        overflow: hidden; }
  .employee-activity .basic-activity .users-activity-counts .activity-overview-block .activity-icon {
    float: left;
    width: 36px;
    font-size: 24px;
    color: #80939e;
    margin-right: 12px;
    text-align: center; }
  .employee-activity .basic-activity .users-activity-counts .activity-overview-block .activity-content {
    float: left;
    width: calc(100% - 48px); }
    .employee-activity .basic-activity .users-activity-counts .activity-overview-block .activity-content .activity-count {
      color: #003a57;
      font-family: 'Oxygen', sans-serif;
      font-size: 28px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 4px; }
    .employee-activity .basic-activity .users-activity-counts .activity-overview-block .activity-content .endpoint-count {
      font-size: 14px;
      color: #80939e;
      letter-spacing: 1px;
      text-transform: uppercase; }
    .employee-activity .basic-activity .users-activity-counts .activity-overview-block .activity-content .activity-title {
      color: #80939e;
      letter-spacing: 0.5px;
      text-transform: uppercase; }
  .employee-activity .basic-activity .day-slider .range-slider {
    margin: 0 auto;
    max-width: 480px;
    padding: 0 20px; }
    .employee-activity .basic-activity .day-slider .range-slider h3 {
      font-size: 15px;
      display: block;
      clear: both;
      float: none;
      margin-top: 0;
      text-align: center;
      color: #2a2a2a;
      margin-bottom: 10px;
      line-height: 24px; }
      .employee-activity .basic-activity .day-slider .range-slider h3 span {
        color: #003a57;
        font-weight: 400;
        font-size: 16px; }
    .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal {
      height: 12px; }
      .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal .noUi-connect {
        background: #43c3f1; }
      .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal .noUi-handle {
        top: -9px;
        width: 24px;
        height: 24px; }
        .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal .noUi-handle:before {
          left: 9px;
          top: 4px; }
        .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal .noUi-handle:after {
          left: 12px;
          top: 4px; }
      .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal .noUi-pips .noUi-value {
        padding-top: 5px;
        font-size: 14px;
        color: #2a2a2a; }
      .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal .noUi-pips .noUi-value-sub {
        color: #2a2a2a;
        font-size: 14px; }
      .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal .noUi-pips .noUi-marker-horizontal.noUi-marker-sub,
      .employee-activity .basic-activity .day-slider .range-slider .noUi-horizontal .noUi-pips .noUi-marker-horizontal.noUi-marker-large {
        height: 12px;
        background: #cccccc; }

.employee-activity .employee-activity-content {
  width: 100%;
  padding: 20px 15px 15px;
  margin: 0; }
  .employee-activity .employee-activity-content h2 {
    font-family: 'Oxygen', sans-serif;
    font-size: 18px;
    display: inline-block;
    font-weight: 400;
    margin: 0 0 15px;
    color: #003a57; }
  .employee-activity .employee-activity-content h3 {
    font-family: 'Oxygen', sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin: 20px 0 15px;
    color: #003a57;
    display: block; }
    .employee-activity .employee-activity-content h3.starter {
      margin: 0 0 15px; }
  .employee-activity .employee-activity-content .custom-simple-tabs .nav-tabs li a {
    background: #ededed;
    margin-right: 5px;
    border-bottom: 1px solid #dddddd;
    color: #666666; }
    .employee-activity .employee-activity-content .custom-simple-tabs .nav-tabs li a.active {
      background: #ffffff;
      border-bottom-color: #ffffff;
      color: #43c3f1; }
  .employee-activity .employee-activity-content .custom-simple-tabs .tab-content {
    border: 1px solid #dddddd;
    border-top: 0;
    padding: 15px; }
    .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .column-chart .ngx-charts-outer, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .column-chart .ngx-charts-outer, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .column-chart .ngx-charts-outer {
      padding: 0 0 20px;
      max-height: 320px;
      height: auto;
      overflow: hidden;
      margin: 0 auto;
      display: block; }
    .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday {
      padding: 0;
      margin-bottom: 15px;
      margin-top: 15px;
      background: #ffffff;
      border: 1px solid #ededed;
      box-shadow: 2px 2px 8px #ededed;
      height: auto;
      overflow: hidden; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday ngx-charts-line-chart, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday ngx-charts-line-chart, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday ngx-charts-line-chart {
        padding: 20px 20px;
        height: auto;
        overflow: hidden;
        display: block; }
        .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday ngx-charts-line-chart .ngx-charts, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday ngx-charts-line-chart .ngx-charts, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday ngx-charts-line-chart .ngx-charts {
          padding-top: 20px; }
          .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday ngx-charts-line-chart .ngx-charts defs, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday ngx-charts-line-chart .ngx-charts defs, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday ngx-charts-line-chart .ngx-charts defs {
            display: none; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .legend-title, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .legend-title, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .legend-title {
        display: none; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday h3, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday h3, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday h3 {
        margin: 0 0 20px;
        background: #ededed;
        padding: 15px;
        border-bottom: 1px solid #ededed; }
        .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday h3 span, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday h3 span, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday h3 span {
          font-size: 14px;
          color: #999999;
          padding-left: 10px; }
          .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday h3 span select, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday h3 span select, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday h3 span select {
            border: 0;
            box-shadow: 0 0 0;
            background: transparent; }
            .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday h3 span select:focus, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday h3 span select:focus, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday h3 span select:focus {
              outline: none; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .group-chart-apps, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .group-chart-apps, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .group-chart-apps {
        padding: 10px 25px 25px; }
        .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .group-chart-apps .custom-legend, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .group-chart-apps .custom-legend, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .group-chart-apps .custom-legend {
          padding-bottom: 12px;
          height: auto;
          overflow: hidden; }
          .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .group-chart-apps .custom-legend .legends ul, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .group-chart-apps .custom-legend .legends ul, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .group-chart-apps .custom-legend .legends ul {
            padding: 0;
            margin: 0;
            width: 100%;
            height: auto;
            overflow: hidden;
            text-align: center; }
            .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .group-chart-apps .custom-legend .legends ul li, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .group-chart-apps .custom-legend .legends ul li, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .group-chart-apps .custom-legend .legends ul li {
              list-style: none;
              float: none;
              display: inline-block;
              padding: 0 5px; }
              .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .group-chart-apps .custom-legend .legends ul li a:hover, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .group-chart-apps .custom-legend .legends ul li a:hover, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .group-chart-apps .custom-legend .legends ul li a:hover {
                text-decoration: none; }
              .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-titles, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-titles, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-titles {
                color: #7a7a7a;
                line-height: 21px;
                font-size: 12px; }
              .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-circle, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-circle, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-circle {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #7a7a7a;
                margin-right: 4px;
                display: block;
                float: left;
                line-height: 18px;
                margin-top: 4px; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .pie-grid-parent, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .pie-grid-parent, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .pie-grid-parent {
        display: grid;
        min-height: 500px; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-perday .padding-20, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-perday .padding-20, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-perday .padding-20 {
        padding: 20px; }
    .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .margin-top-zero, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .margin-top-zero, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .margin-top-zero {
      margin-top: 0; }
    .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .inner-tabset .tab-content, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .inner-tabset .tab-content, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .inner-tabset .tab-content {
      padding: 15px; }
    .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .inner-tabset .activity-perday, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .inner-tabset .activity-perday, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .inner-tabset .activity-perday {
      margin: 0; }
    .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table {
      padding-top: 15px; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header {
        height: auto;
        overflow: hidden;
        display: block;
        clear: both;
        margin-bottom: 10px; }
        .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header h3, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header h3, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header h3 {
          float: left;
          margin: 0;
          width: calc(100% - 240px);
          line-height: 32px; }
        .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table {
          float: right;
          width: 240px;
          position: relative; }
          .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table select, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table select, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table select {
            height: 32px;
            width: 60px;
            background: transparent;
            border: 1px solid #dddddd;
            float: left; }
            .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table select:focus, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table select:focus, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table select:focus {
              outline: none; }
          .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table .search-table-input, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table .search-table-input, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table .search-table-input {
            position: relative;
            height: auto;
            overflow: hidden;
            width: calc(100% - 65px);
            float: left;
            margin-right: 5px; }
            .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table .search-table-input input, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table .search-table-input input, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table .search-table-input input {
              border: 1px solid #dddddd;
              height: 32px;
              padding: 0 10px;
              border-radius: 6px;
              position: relative;
              z-index: 1;
              width: 100%;
              transition: all 0.5s ease; }
              .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table .search-table-input input:focus, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table .search-table-input input:focus, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table .search-table-input input:focus {
                outline: none;
                transition: all 0.5s ease;
                border-color: #cccccc; }
            .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table .search-table-input i, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table .search-table-input i, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table .search-table-input i {
              position: absolute;
              z-index: 9;
              top: 10px;
              right: 12px;
              font-size: 12px;
              color: #aaa; }
          .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table .reset-button, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table .reset-button, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table .reset-button {
            border: 0;
            height: 32px;
            text-align: center;
            border-radius: 20px;
            position: relative;
            z-index: 1;
            width: 24px;
            margin-left: 4px;
            float: left;
            color: #2a2a2a; }
            .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .general-activity .activity-table .activity-table-header .search-table .reset-button:hover, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .activity-table .activity-table-header .search-table .reset-button:hover, .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .activity-table .activity-table-header .search-table .reset-button:hover {
              background: none;
              color: #43c3f1; }
    .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .alert-activity .alert-activity-table-chart .alert-activity-chart {
      margin-top: 15px; }
    .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .file-activity-outer {
      padding: 0;
      margin-bottom: 15px;
      background: #ffffff;
      border: 1px solid #ededed;
      box-shadow: 2px 2px 8px #ededed;
      height: auto;
      overflow: hidden; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .file-activity-outer .table-title {
        margin: 0 0 20px;
        background: #ededed;
        padding: 15px;
        border-bottom: 1px solid #ededed; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .file-activity-outer .file-activity-search-table .file-activity-search {
        text-align: right;
        margin-right: 15px; }
        .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .file-activity-outer .file-activity-search-table .file-activity-search select {
          height: 32px;
          background: transparent;
          border: 1px solid #dddddd; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .file-activity-outer .file-activity-search-table .file-activity-table {
        display: inline-block !important;
        vertical-align: top;
        float: none; }
      .employee-activity .employee-activity-content .custom-simple-tabs .tab-content .file-activity .file-activity-outer .file-empty-row {
        height: 100px;
        vertical-align: middle;
        text-align: center;
        line-height: 80px; }

.group-activity .activity-perday {
  padding: 0;
  margin-bottom: 15px;
  margin-top: 15px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 2px 2px 8px #ededed;
  height: auto;
  overflow: hidden; }
  .group-activity .activity-perday ngx-charts-line-chart {
    padding: 20px 20px;
    height: auto;
    overflow: hidden;
    display: block; }
    .group-activity .activity-perday ngx-charts-line-chart .ngx-charts {
      padding-top: 20px; }
      .group-activity .activity-perday ngx-charts-line-chart .ngx-charts defs {
        display: none; }
  .group-activity .activity-perday .legend-title {
    display: none; }
  .group-activity .activity-perday h3 {
    margin: 0 0 20px;
    background: #ededed;
    padding: 15px;
    border-bottom: 1px solid #ededed; }
    .group-activity .activity-perday h3 span {
      font-size: 14px;
      color: #999999;
      padding-left: 10px; }
      .group-activity .activity-perday h3 span select {
        border: 0;
        box-shadow: 0 0 0;
        background: transparent; }
        .group-activity .activity-perday h3 span select:focus {
          outline: none; }
  .group-activity .activity-perday .group-chart-apps {
    padding: 10px 25px 25px; }
    .group-activity .activity-perday .group-chart-apps .custom-legend {
      padding-bottom: 12px;
      height: auto;
      overflow: hidden; }
      .group-activity .activity-perday .group-chart-apps .custom-legend .legends ul {
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        text-align: center; }
        .group-activity .activity-perday .group-chart-apps .custom-legend .legends ul li {
          list-style: none;
          float: none;
          display: inline-block;
          padding: 0 5px; }
          .group-activity .activity-perday .group-chart-apps .custom-legend .legends ul li a:hover {
            text-decoration: none; }
          .group-activity .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-titles {
            color: #7a7a7a;
            line-height: 21px;
            font-size: 12px; }
          .group-activity .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #7a7a7a;
            margin-right: 4px;
            display: block;
            float: left;
            line-height: 18px;
            margin-top: 4px; }
  .group-activity .activity-perday .pie-grid-parent {
    display: grid;
    min-height: 500px; }
  .group-activity .activity-perday .padding-20 {
    padding: 20px; }

.group-activity .group-chart {
  display: block;
  padding: 0 25px; }

.dashboard-blocks-full-width {
  min-height: auto;
  margin-bottom: 25px;
  background: #ffffff;
  padding: 15px 20px 20px;
  box-shadow: 3px 3px 6px #ededed;
  border: 1px solid #ededed; }
  .dashboard-blocks-full-width h4 {
    margin: 5px 0 20px;
    text-align: left;
    display: inline-block;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    color: #003a57;
    position: relative;
    padding-bottom: 10px; }
    .dashboard-blocks-full-width h4:after {
      height: 3px;
      width: 50px;
      background: #43c3f1;
      position: absolute;
      content: "";
      bottom: 0;
      left: 0; }

.vis-timeline {
  border: 1px solid #ededed;
  padding: 20px;
  height: 200px; }
  .vis-timeline .vis-major {
    font-weight: 600; }
  .vis-timeline .vis-text {
    padding: 8px;
    font-size: 12px;
    color: #999999; }
  .vis-timeline .vis-item {
    margin: 0;
    padding: 0;
    border-color: #43c3f1;
    background: rgba(67, 195, 241, 0.3); }
    .vis-timeline .vis-item .vis-item-content {
      padding: 2px 1px;
      font-size: 10px; }
  .vis-timeline .vis-current-time {
    background: #43c3f1; }

#keystroke-visualization .vis-item {
  border-color: #FFA1B5;
  background: rgba(255, 161, 181, 0.3); }

#keystroke-visualization .vis-current-time {
  background: #FFA1B5; }

.timeline-menu {
  float: right; }
  .timeline-menu button {
    background: #ededed;
    border-radius: 3px;
    margin-bottom: 10px;
    color: #2a2a2a;
    border: 0;
    padding: 3px 6px;
    margin-left: 5px;
    font-size: 14px;
    float: right;
    transition: all 0.5s ease;
    width: 24px; }
    .timeline-menu button.rolling-active {
      background: #43c3f1; }
    .timeline-menu button:hover {
      background: #003a57;
      color: #ffffff;
      transition: all 0.5s ease; }
    .timeline-menu button:focus {
      outline: none; }

alert-summary .alert-summary-title-block {
  height: 115px;
  position: sticky;
  top: 0;
  z-index: 11111;
  padding: 12px 15px;
  background: #ededed;
  overflow: hidden;
  width: 100%;
  display: block; }
  alert-summary .alert-summary-title-block .alert-title {
    height: 100%;
    align-items: center;
    display: flex; }
    alert-summary .alert-summary-title-block .alert-title .page-title {
      font-family: 'Oxygen', sans-serif;
      font-size: 21px;
      display: block;
      font-weight: 400;
      margin: 0;
      color: #003a57;
      float: left;
      padding: 0;
      line-height: 30px;
      height: auto; }
  alert-summary .alert-summary-title-block .day-slider .range-slider {
    margin: 0 auto;
    max-width: 480px;
    padding: 0 20px; }
    alert-summary .alert-summary-title-block .day-slider .range-slider h3 {
      font-size: 15px;
      display: block;
      clear: both;
      float: none;
      margin-top: 0;
      text-align: center;
      color: #2a2a2a;
      margin-bottom: 10px;
      line-height: 24px; }
      alert-summary .alert-summary-title-block .day-slider .range-slider h3 span {
        color: #003a57;
        font-weight: 400;
        font-size: 16px; }
    alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal {
      height: 12px; }
      alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal .noUi-connect {
        background: #43c3f1; }
      alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal .noUi-handle {
        top: -9px;
        width: 24px;
        height: 24px; }
        alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal .noUi-handle:before {
          left: 9px;
          top: 4px; }
        alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal .noUi-handle:after {
          left: 12px;
          top: 4px; }
      alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal .noUi-pips .noUi-value {
        padding-top: 5px;
        font-size: 14px;
        color: #2a2a2a; }
      alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal .noUi-pips .noUi-value-sub {
        color: #2a2a2a;
        font-size: 14px; }
      alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal .noUi-pips .noUi-marker-horizontal.noUi-marker-sub,
      alert-summary .alert-summary-title-block .day-slider .range-slider .noUi-horizontal .noUi-pips .noUi-marker-horizontal.noUi-marker-large {
        height: 12px;
        background: #cccccc; }

alert-summary .alert-summary-content {
  width: 100%;
  padding: 20px 15px 15px;
  margin: 0; }
  alert-summary .alert-summary-content .white-box {
    padding: 15px;
    background: none;
    margin-bottom: 20px; }
    alert-summary .alert-summary-content .white-box .row-in-br {
      border-right: none; }
    alert-summary .alert-summary-content .white-box .row.row-in {
      display: flex; }
      alert-summary .alert-summary-content .white-box .row.row-in .summary-box {
        width: 19%;
        margin-right: 23px;
        background: #f5f5f5;
        min-height: 100px;
        display: flex;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between; }
      alert-summary .alert-summary-content .white-box .row.row-in .green-bg {
        background: #b8efe9 !important; }
      alert-summary .alert-summary-content .white-box .row.row-in .blue-bg {
        background: #b6d5f1 !important; }
    alert-summary .alert-summary-content .white-box .marginRight-none {
      margin-right: 0px !important; }
    alert-summary .alert-summary-content .white-box .b-0 {
      border: none !important; }
    alert-summary .alert-summary-content .white-box .col-in {
      list-style: none;
      padding: 0px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin: 0px;
      flex-direction: row-reverse; }
      alert-summary .alert-summary-content .white-box .col-in li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px; }
        alert-summary .alert-summary-content .white-box .col-in li .circle {
          display: inline-block;
          border-radius: 100%;
          text-align: center;
          background: #43c3f1;
          color: #ffffff; }
        alert-summary .alert-summary-content .white-box .col-in li .circle-md {
          width: 60px;
          padding-top: 8px;
          height: 60px;
          font-size: 28px !important; }
        alert-summary .alert-summary-content .white-box .col-in li h3 {
          font-size: 36px;
          font-weight: 100;
          margin: 10px 0; }
        alert-summary .alert-summary-content .white-box .col-in li .counter span {
          color: #b3c0c0; }
        alert-summary .alert-summary-content .white-box .col-in li.col-last {
          float: right; }
        alert-summary .alert-summary-content .white-box .col-in li.col-middle {
          width: 50%; }
  alert-summary .alert-summary-content .boundry-box {
    padding: 0;
    border: 1px solid #dddddd;
    background: #ffffff;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07); }
    alert-summary .alert-summary-content .boundry-box h4 {
      font-size: 15px;
      margin: 0;
      padding: 15px 20px;
      text-align: left;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      font-weight: 600;
      color: #003a57; }
    alert-summary .alert-summary-content .boundry-box .chart-container {
      padding: 15px;
      height: auto;
      min-height: 330px;
      overflow: hidden; }
  alert-summary .alert-summary-content .activity-perday {
    padding: 0;
    margin-bottom: 15px;
    margin-top: 15px;
    background: #ffffff;
    border: 1px solid #ededed;
    box-shadow: 2px 2px 8px #ededed;
    height: auto;
    overflow: hidden; }
    alert-summary .alert-summary-content .activity-perday ngx-charts-line-chart {
      padding: 20px 20px;
      height: auto;
      overflow: hidden;
      display: block; }
      alert-summary .alert-summary-content .activity-perday ngx-charts-line-chart .ngx-charts {
        padding-top: 20px; }
        alert-summary .alert-summary-content .activity-perday ngx-charts-line-chart .ngx-charts defs {
          display: none; }
    alert-summary .alert-summary-content .activity-perday .legend-title {
      display: none; }
    alert-summary .alert-summary-content .activity-perday h3 {
      margin: 0 0 20px;
      background: #ededed;
      padding: 15px;
      border-bottom: 1px solid #ededed; }
      alert-summary .alert-summary-content .activity-perday h3 span {
        font-size: 14px;
        color: #999999;
        padding-left: 10px; }
        alert-summary .alert-summary-content .activity-perday h3 span select {
          border: 0;
          box-shadow: 0 0 0;
          background: transparent; }
          alert-summary .alert-summary-content .activity-perday h3 span select:focus {
            outline: none; }
    alert-summary .alert-summary-content .activity-perday .group-chart-apps {
      padding: 10px 25px 25px; }
      alert-summary .alert-summary-content .activity-perday .group-chart-apps .custom-legend {
        padding-bottom: 12px;
        height: auto;
        overflow: hidden; }
        alert-summary .alert-summary-content .activity-perday .group-chart-apps .custom-legend .legends ul {
          padding: 0;
          margin: 0;
          width: 100%;
          height: auto;
          overflow: hidden;
          text-align: center; }
          alert-summary .alert-summary-content .activity-perday .group-chart-apps .custom-legend .legends ul li {
            list-style: none;
            float: none;
            display: inline-block;
            padding: 0 5px; }
            alert-summary .alert-summary-content .activity-perday .group-chart-apps .custom-legend .legends ul li a:hover {
              text-decoration: none; }
            alert-summary .alert-summary-content .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-titles {
              color: #7a7a7a;
              line-height: 21px;
              font-size: 12px; }
            alert-summary .alert-summary-content .activity-perday .group-chart-apps .custom-legend .legends ul li .legend-circle {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #7a7a7a;
              margin-right: 4px;
              display: block;
              float: left;
              line-height: 18px;
              margin-top: 4px; }
    alert-summary .alert-summary-content .activity-perday .pie-grid-parent {
      display: grid;
      min-height: 500px; }
    alert-summary .alert-summary-content .activity-perday .padding-20 {
      padding: 20px; }
  alert-summary .alert-summary-content .alert-summary-table .table-border {
    border: 1px solid #dddddd;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07); }
    alert-summary .alert-summary-content .alert-summary-table .table-border .row {
      padding-top: 10px; }
      alert-summary .alert-summary-content .alert-summary-table .table-border .row .active-hours-title {
        padding: 5px;
        margin: 0 0 10px 10px; }
      alert-summary .alert-summary-content .alert-summary-table .table-border .row button.dropdown-toggle.btn.btn-primary {
        background: transparent;
        border: none;
        color: #000000;
        border-bottom: 1px solid #dddddd; }
        alert-summary .alert-summary-content .alert-summary-table .table-border .row button.dropdown-toggle.btn.btn-primary .dropdown-toggle[_ngcontent-c4] .caret[_ngcontent-c4] {
          margin-left: 10px; }

view-alerts .rules-dashboard {
  width: 100%;
  padding: 20px 15px 15px;
  margin: 0; }
  view-alerts .rules-dashboard .white-box {
    padding: 15px;
    background: none;
    margin-bottom: 20px; }
    view-alerts .rules-dashboard .white-box .row-in-br {
      border-right: none; }
    view-alerts .rules-dashboard .white-box .row.row-in {
      display: flex; }
      view-alerts .rules-dashboard .white-box .row.row-in .rules-dashboard-box {
        margin-right: 23px;
        background: #f5f5f5;
        min-height: 100px;
        display: flex;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between; }
        view-alerts .rules-dashboard .white-box .row.row-in .rules-dashboard-box .col-middle .block-title {
          font-size: 16px; }
        view-alerts .rules-dashboard .white-box .row.row-in .rules-dashboard-box:last-child {
          margin-right: 0; }
      view-alerts .rules-dashboard .white-box .row.row-in .green-bg {
        background: #b8efe9 !important; }
      view-alerts .rules-dashboard .white-box .row.row-in .blue-bg {
        background: #b6d5f1 !important; }
    view-alerts .rules-dashboard .white-box .marginRight-none {
      margin-right: 0px !important; }
    view-alerts .rules-dashboard .white-box .b-0 {
      border: none !important; }
    view-alerts .rules-dashboard .white-box .col-in {
      list-style: none;
      padding: 0px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin: 0px;
      flex-direction: row-reverse; }
      view-alerts .rules-dashboard .white-box .col-in li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px; }
        view-alerts .rules-dashboard .white-box .col-in li .circle {
          display: inline-block;
          border-radius: 100%;
          text-align: center;
          background: #43c3f1;
          color: #ffffff; }
        view-alerts .rules-dashboard .white-box .col-in li .circle-md {
          width: 60px;
          padding-top: 8px;
          height: 60px;
          font-size: 28px !important; }
        view-alerts .rules-dashboard .white-box .col-in li h3 {
          font-size: 36px;
          font-weight: 100;
          margin: 10px 0; }
        view-alerts .rules-dashboard .white-box .col-in li .counter span {
          color: #b3c0c0; }
        view-alerts .rules-dashboard .white-box .col-in li.col-last {
          float: right; }
        view-alerts .rules-dashboard .white-box .col-in li.col-middle {
          width: 50%; }
  view-alerts .rules-dashboard .boundry-box {
    padding: 0;
    border: 1px solid #dddddd;
    background: #ffffff;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07); }
    view-alerts .rules-dashboard .boundry-box h4 {
      font-size: 15px;
      margin: 0;
      padding: 15px 20px;
      text-align: left;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      font-weight: 600;
      color: #003a57; }
    view-alerts .rules-dashboard .boundry-box .chart-container {
      padding: 15px;
      height: auto;
      min-height: 330px;
      overflow: hidden; }
  view-alerts .rules-dashboard .rule-dashboard-table .table-search-field {
    padding: 8px;
    margin: 15px auto;
    width: 30%;
    border: 0;
    outline: none;
    border-bottom: 1px solid #dddddd; }

view-alerts-log .log-display {
  background-color: #ffffff;
  border: none; }

.alert-nested-form input[type="checkbox"] {
  width: 13px !important;
  height: 13px !important; }

.formly-inline {
  padding: 15px;
  background: #ededed;
  height: auto;
  overflow: auto;
  margin-bottom: 15px;
  border: 1px solid #ededed; }

.usb-formly .field-group-customclass {
  margin: 0; }

.multi-line-form .alert-create-submit {
  padding: 5px 10px;
  background: #003a57;
  border-radius: 0;
  border: 0;
  color: #ffffff; }

.graphical-alert {
  float: right;
  color: #ededed;
  background: #003a57;
  border-radius: 3px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  margin-left: 5px; }
  .graphical-alert:hover {
    text-decoration: none;
    color: #ededed; }

.alert-form .main-container .alert-form .cuppa-dropdown .selected-list .c-btn {
  box-shadow: none; }

.alert-form .radio-buttons input[type="radio"] {
  float: none; }

.alert-nested-form {
  margin-bottom: 20px;
  border-color: #dddddd;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
  .alert-nested-form .alert-nested-head {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    color: #333333; }
  .alert-nested-form .margin-top20 {
    margin-top: 20px; }
  .alert-nested-form ng2-dual-list-box h4.text-center.vertical-spacing-5 {
    text-align: left; }
  .alert-nested-form input[type="radio"],
  .alert-nested-form input[type="checkbox"] {
    margin-right: 4px; }

.title-block {
  padding: 12px 15px;
  background: #ededed;
  height: auto;
  overflow: hidden;
  width: 100%;
  display: block; }
  .title-block .page-title {
    font-family: 'Oxygen', sans-serif;
    font-size: 21px;
    display: block;
    font-weight: 400;
    margin: 0;
    color: #003a57;
    float: left;
    padding: 0;
    line-height: 30px;
    height: auto; }
  .title-block .addToggle {
    float: right;
    color: #ededed;
    background: #003a57;
    border-radius: 3px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px; }

.one-line-form {
  padding: 15px;
  background: #ededed;
  height: auto;
  overflow: auto;
  margin-bottom: 15px;
  border: 1px solid #ededed; }
  .one-line-form input {
    float: left;
    margin-right: 15px;
    height: 32px;
    padding: 5px 10px;
    margin-bottom: 0;
    min-width: 320px;
    border: 1px solid #dddddd; }
  .one-line-form .company-alternate-IP {
    min-width: 0; }
  .one-line-form label {
    float: left;
    margin-right: 20px;
    line-height: 32px;
    margin-bottom: 0;
    font-weight: 600; }
  .one-line-form select {
    min-width: 320px;
    float: left;
    margin-right: 15px;
    height: 32px;
    border: 1px solid #ffffff;
    padding: 3px 10px;
    border-radius: 3px; }
  .one-line-form button {
    float: left;
    margin-bottom: 0;
    background: #003a57;
    border: 0;
    color: #ffffff;
    height: 32px;
    padding: 5px 15px; }
    .one-line-form button:focus {
      outline: none; }
    .one-line-form button [disabled], .one-line-form button:disabled {
      opacity: 0.5;
      cursor: not-allowed; }

.multi-line-form {
  padding: 20px;
  background: #ededed;
  border: 1px solid #ededed;
  margin-bottom: 15px; }
  .multi-line-form .zero-padding {
    padding: 0; }
  .multi-line-form .margin-bottom-15 {
    margin-bottom: 15px; }
  .multi-line-form .custom-label,
  .multi-line-form label {
    font-weight: 400;
    color: #000000;
    font-size: 14px;
    text-align: left;
    line-height: 32px; }
  .multi-line-form .ng2-tag-input__text-input {
    height: 32px !important;
    border: 1px solid #dddddd !important;
    margin-top: 3px; }
  .multi-line-form input,
  .multi-line-form select {
    width: 100%;
    height: 32px;
    border: 1px solid #dddddd;
    padding: 3px 10px;
    border-radius: 3px;
    background: #ffffff;
    max-width: 275px; }
  .multi-line-form .radio-buttons input[type="radio"] {
    width: 18px;
    float: left;
    padding: 0;
    margin: 0;
    height: 18px; }
  .multi-line-form .radio-buttons span {
    float: left;
    margin: 0 0 10px 6px; }
  .multi-line-form .license-radiobtn .radio-inline {
    margin-left: 0; }
    .multi-line-form .license-radiobtn .radio-inline input {
      margin-top: 10px; }
  .multi-line-form .bulk-upload-separator {
    position: relative;
    font-size: 18px;
    color: #aaa;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px; }
    .multi-line-form .bulk-upload-separator .separate-border {
      background-color: #cdcdcd;
      height: 1px;
      margin-top: 0px !important;
      margin-bottom: 0px !important; }
    .multi-line-form .bulk-upload-separator .span-or {
      display: block;
      position: absolute;
      left: 50%;
      font-size: 14px;
      top: 0;
      margin-left: -25px;
      background-color: #ededed;
      width: 50px;
      text-align: center; }
  .multi-line-form .uploader-options {
    padding: 15px 0 0; }
    .multi-line-form .uploader-options .upload-options {
      height: auto;
      overflow: hidden; }
      .multi-line-form .uploader-options .upload-options button {
        padding-top: 3px; }
    .multi-line-form .uploader-options .upload-progress {
      height: auto;
      overflow: hidden;
      margin-bottom: 10px; }
      .multi-line-form .uploader-options .upload-progress h4 {
        font-size: 14px;
        margin-top: 0;
        clear: both;
        float: none; }
      .multi-line-form .uploader-options .upload-progress .progress {
        margin: 0;
        height: 10px;
        border-radius: 10px;
        background: #ffffff; }
  .multi-line-form ss-multiselect-dropdown .input-group-btn button {
    width: 24px;
    min-width: 24px;
    border: 0; }
  .multi-line-form ss-multiselect-dropdown button {
    width: 100%;
    text-align: left;
    border: 1px solid #dddddd;
    min-width: 275px; }
    .multi-line-form ss-multiselect-dropdown button:hover {
      background: #ffffff;
      border: 1px solid #ffffff; }
    .multi-line-form ss-multiselect-dropdown button span {
      text-align: right;
      float: right;
      margin-top: 8px; }
  .multi-line-form ss-multiselect-dropdown .dropdown-menu {
    width: 100%;
    margin: 0;
    padding: 0; }
    .multi-line-form ss-multiselect-dropdown .dropdown-menu li {
      border-bottom: 1px solid #f5f5f5; }
      .multi-line-form ss-multiselect-dropdown .dropdown-menu li a {
        padding: 6px 10px; }
        .multi-line-form ss-multiselect-dropdown .dropdown-menu li a input {
          width: 15px;
          height: 15px;
          margin-right: 6px; }
      .multi-line-form ss-multiselect-dropdown .dropdown-menu li.divider {
        margin: 0;
        border: 0; }
      .multi-line-form ss-multiselect-dropdown .dropdown-menu li.search {
        padding: 5px; }
        .multi-line-form ss-multiselect-dropdown .dropdown-menu li.search span button {
          padding: 0 4px 0 8px;
          margin: 0;
          font-size: 14px; }
        .multi-line-form ss-multiselect-dropdown .dropdown-menu li.search input {
          height: 36px;
          border: 1px solid #cccccc; }

.table-search, .active-hour-seven-days .table-search-summary {
  float: left;
  position: relative; }
  .table-search input, .active-hour-seven-days .table-search-summary input {
    z-index: 1;
    position: relative;
    border: 1px solid #dddddd;
    width: 320px;
    border-radius: 20px;
    padding: 5px 10px;
    margin-bottom: 10px;
    font-size: 13px; }
    .table-search input:focus, .active-hour-seven-days .table-search-summary input:focus {
      outline: none; }
  .table-search .fa, .active-hour-seven-days .table-search-summary .fa {
    z-index: 9;
    position: absolute;
    top: 8px;
    right: 12px;
    font-size: 13px;
    color: #aaa; }

.activity-filter {
  min-height: 72px; }
  .activity-filter .label {
    font-size: 14px;
    float: left;
    color: #2a2a2a;
    line-height: 28px; }
  .activity-filter select {
    border: 1px solid #dddddd; }

.show-entries {
  float: right;
  padding: 5px 0; }
  .show-entries.dashboard-entries {
    padding: 5px 0 18px 0; }

ss-multiselect-dropdown .dropdown {
  display: inline-block; }
  ss-multiselect-dropdown .dropdown .dropdown-menu {
    display: flex;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #555;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 4px; }
    ss-multiselect-dropdown .dropdown .dropdown-menu .input-group {
      display: flex;
      padding: 5px; }
      ss-multiselect-dropdown .dropdown .dropdown-menu .input-group .input-group-prepend {
        padding: 5px 12px;
        background-color: #ededed;
        border: 1px solid #cccccc;
        border-right: 0;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      ss-multiselect-dropdown .dropdown .dropdown-menu .input-group .input-group-append button {
        min-width: auto;
        border: none; }
    ss-multiselect-dropdown .dropdown .dropdown-menu .dropdown-item {
      padding: 6px 10px;
      display: block;
      padding: 3px 20px;
      background-color: transparent;
      border-bottom: 1px solid #ededed;
      font-weight: 400;
      line-height: 1.42857143;
      color: #333333;
      white-space: nowrap;
      text-decoration: none;
      cursor: pointer; }
      ss-multiselect-dropdown .dropdown .dropdown-menu .dropdown-item span {
        display: flex;
        line-height: 32px;
        outline: none; }
        ss-multiselect-dropdown .dropdown .dropdown-menu .dropdown-item span input {
          width: 15px;
          height: 20px;
          margin-right: 6px;
          height: 25px;
          border: 1px solid #dedede;
          padding: 3px 10px;
          border-radius: 3px;
          background: #ffffff;
          max-width: 275px;
          z-index: -9999; }
        ss-multiselect-dropdown .dropdown .dropdown-menu .dropdown-item span span {
          line-height: 32px !important; }

.white-box {
  background: #f5f5f5;
  padding: 25px;
  margin-bottom: 20px; }
  .white-box .row-in-br {
    border-right: 1px solid rgba(120, 130, 140, 0.13); }
  .white-box .b-0 {
    border: none !important; }
  .white-box .col-in {
    list-style: none;
    padding: 0px;
    margin: 0px; }
    .white-box .col-in li {
      display: inline-block;
      vertical-align: middle;
      padding: 0 10px; }
      .white-box .col-in li .circle {
        display: inline-block;
        border-radius: 100%;
        text-align: center;
        background: #43c3f1;
        color: #ffffff; }
      .white-box .col-in li .circle-md {
        width: 60px;
        padding-top: 8px;
        height: 60px;
        font-size: 28px !important; }
      .white-box .col-in li h3 {
        font-size: 36px;
        font-weight: 100;
        margin: 10px 0; }
      .white-box .col-in li .counter span {
        color: #b3c0c0; }
      .white-box .col-in li.col-last {
        float: right; }
      .white-box .col-in li.col-middle {
        width: 40%; }

.active-user-block .boundry-box {
  padding: 0;
  border: 1px solid #dddddd;
  background: #ffffff;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07); }
  .active-user-block .boundry-box h4 {
    font-size: 15px;
    margin: 0;
    padding: 15px 20px;
    text-align: left;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 600;
    color: #003a57; }
  .active-user-block .boundry-box .chart-container {
    padding: 15px;
    height: auto;
    min-height: 330px;
    overflow: hidden; }

.active-hours {
  padding: 30px 0;
  /* HEX CONTENT */
  /* HOVER EFFECT  */ }
  .active-hours .generated-time {
    text-align: right;
    line-height: 30px; }
  .active-hours .department-list {
    padding: 0;
    margin: 0; }
  .active-hours #hexGrid {
    overflow: hidden;
    width: 90%;
    margin: 0 auto;
    padding: 0.707% 0; }
  .active-hours #hexGrid:after {
    content: "";
    display: block;
    clear: both; }
  .active-hours .hex {
    position: relative;
    list-style-type: none;
    float: left;
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(-60deg) skewY(30deg);
    -ms-transform: rotate(-60deg) skewY(30deg);
    transform: rotate(-60deg) skewY(30deg); }
  .active-hours .hex * {
    position: absolute;
    visibility: visible; }
  .active-hours .hexIn {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #ffffff;
    background: #757575;
    overflow: hidden;
    -webkit-transform: skewY(-30deg) rotate(60deg);
    -ms-transform: skewY(-30deg) rotate(60deg);
    transform: skewY(-30deg) rotate(60deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
  .active-hours .hex h1,
  .active-hours .hex p {
    width: 100%;
    padding: 0 5%;
    background-color: #000000;
    color: #ffffff;
    -webkit-transition: top .2s ease-out, bottom .2s ease-out, .2s padding .2s ease-out;
    transition: top .2s ease-out, bottom .2s ease-out, .2s padding .2s ease-out; }
  .active-hours .hex h1 {
    bottom: 110%;
    font-style: italic;
    font-weight: normal;
    font-size: 1.5em;
    padding-top: 100%;
    padding-bottom: 100%; }
  .active-hours .hex h2 {
    font-size: 1.5em;
    width: 100%;
    top: 42%;
    left: 0;
    color: #ffffff;
    cursor: pointer; }
  .active-hours .hex h3 {
    font-size: 4em;
    width: 100%;
    top: 46%;
    left: 0;
    color: #ffffff; }
  .active-hours .hex h1:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 45%;
    width: 10%;
    text-align: center;
    z-index: 1;
    border-bottom: 2px solid #ffffff; }
  .active-hours .hex p {
    padding-top: 50%;
    top: 110%;
    padding-bottom: 50%; }
  .active-hours .hexIn:hover h1 {
    bottom: 48%;
    padding-bottom: 10%; }
  .active-hours .hexIn:hover p {
    top: 50%;
    padding-top: 10%; }

.active-hour-seven-days {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.07); }
  .active-hour-seven-days .active-hours-title {
    margin-left: 20px;
    margin-bottom: 15px;
    font-weight: 600; }
  .active-hour-seven-days .table-search-summary {
    float: none; }
    .active-hour-seven-days .table-search-summary input {
      width: 100%; }
  .active-hour-seven-days .active-users-list {
    position: relative;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1; }
    .active-hour-seven-days .active-users-list .active-list-title {
      background: #ededed;
      padding: 0 15px;
      font-weight: 600;
      height: 45px;
      line-height: 45px;
      margin-bottom: 3px; }
      .active-hour-seven-days .active-users-list .active-list-title .active-hour-heading {
        cursor: pointer;
        word-break: break-all;
        white-space: nowrap; }
        .active-hour-seven-days .active-users-list .active-list-title .active-hour-heading i {
          font-size: 7px;
          margin-top: 18px;
          vertical-align: top; }
    .active-hour-seven-days .active-users-list li {
      list-style: none;
      display: block;
      min-width: 100%;
      padding: 0;
      background: #ffffff;
      border-left: 5px solid #dddddd;
      margin-bottom: 1px;
      height: 40px;
      clear: both;
      overflow: hidden;
      line-height: 40px; }
      .active-hour-seven-days .active-users-list li a {
        font-size: 15px;
        font-weight: 400;
        color: #003a57;
        transition: all 0.5s ease;
        padding-left: 10px;
        line-height: 40px; }
        .active-hour-seven-days .active-users-list li a:hover {
          text-decoration: none;
          color: #43c3f1;
          transition: all 0.5s ease; }
      .active-hour-seven-days .active-users-list li.active-list-title {
        border: 0;
        font-size: 13px; }
    .active-hour-seven-days .active-users-list .sticky-scroll-li {
      position: sticky;
      width: 100%;
      z-index: 111;
      top: 0; }
    .active-hour-seven-days .active-users-list .dashboard-activehours-noData {
      text-align: center;
      padding: 10px 0px; }

.active-hour-24hrs {
  margin-top: 30px; }

.material.ngx-datatable {
  box-shadow: 0 0 0;
  border: 1px solid #ededed;
  display: block;
  clear: both;
  width: 100%; }
  .material.ngx-datatable .empty-row {
    height: 40px;
    vertical-align: middle;
    text-align: center;
    line-height: 32px; }
  .material.ngx-datatable .datatable-header {
    background: #ededed;
    border-bottom: 2px solid #b3c0c0;
    color: #2a2a2a; }
    .material.ngx-datatable .datatable-header .resize-handle {
      border-right: solid 1px #cccccc; }
    .material.ngx-datatable .datatable-header .datatable-header-cell {
      padding: 8px 15px;
      text-transform: capitalize;
      color: #000000;
      font-weight: 600; }
  .material.ngx-datatable .datatable-footer {
    background: rgba(150, 150, 150, 0.06); }
    .material.ngx-datatable .datatable-footer .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 15px;
      font-size: 14px;
      color: #000000; }
      @media (min-width: 1170px) and (max-width: 1173px) {
        .material.ngx-datatable .datatable-footer .page-count {
          padding: 0 5px; } }
    .material.ngx-datatable .datatable-footer .datatable-pager .pager {
      margin-top: 5px; }
      .material.ngx-datatable .datatable-footer .datatable-pager .pager li {
        float: left; }
        .material.ngx-datatable .datatable-footer .datatable-pager .pager li a {
          vertical-align: middle;
          height: 24px;
          line-height: 24px;
          padding: 0 5px;
          margin: 5px 1px;
          font-size: 14px; }
          .material.ngx-datatable .datatable-footer .datatable-pager .pager li a .icon-prev,
          .material.ngx-datatable .datatable-footer .datatable-pager .pager li a .icon-left,
          .material.ngx-datatable .datatable-footer .datatable-pager .pager li a .icon-skip,
          .material.ngx-datatable .datatable-footer .datatable-pager .pager li a .icon-right {
            padding: 0;
            font-size: 16px;
            line-height: 27px; }
          .material.ngx-datatable .datatable-footer .datatable-pager .pager li a i {
            padding: 0; }
        .material.ngx-datatable .datatable-footer .datatable-pager .pager li.active a {
          background-color: #003a57;
          font-weight: normal;
          color: #ffffff;
          border-color: #003a57; }
  .material.ngx-datatable .datatable-body .datatable-row-wrapper {
    border-bottom: 1px solid #ededed;
    color: #2a2a2a;
    height: auto !important; }
    .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row {
      height: auto !important; }
      .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell {
        padding: 8px 15px;
        font-size: 14px;
        height: auto !important; }
        .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell select {
          border: 1px solid #dddddd;
          background: #ffffff;
          color: #7a7a7a;
          font-size: 13px;
          width: 95%;
          height: 25px; }
        .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell a {
          color: #000000;
          text-decoration: none;
          margin-right: 10px;
          display: inline-block; }
          .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell a:hover {
            color: #43c3f1;
            text-decoration: none; }
        .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell .custom-table-actions {
          cursor: pointer; }
          .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell .custom-table-actions span {
            color: #003a57;
            font-size: 16px; }
        .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell ul {
          padding: 0;
          margin: 0; }
          .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell ul li {
            list-style: none;
            float: left;
            margin: 0 5px 0 0; }
            .material.ngx-datatable .datatable-body .datatable-row-wrapper .datatable-body-row .datatable-body-cell ul li:last-child span {
              display: none; }
    .material.ngx-datatable .datatable-body .datatable-row-wrapper:last-child {
      border: 0; }

#settings-table datatable-body datatable-body-cell:nth-child(2) {
  overflow: auto;
  word-break: break-all;
  white-space: initial; }

.play-video span {
  font-size: 23px; }

login canvas, forgot canvas, reset canvas {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -9; }

.login-section .login-block {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: auto;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif; }
  .login-section .login-block .panel-login {
    width: 320px;
    float: right; }
    .login-section .login-block .panel-login .login-container {
      position: absolute;
      width: 320px;
      height: 100vh;
      top: 0;
      bottom: 0;
      background: rgba(60, 60, 60, 0.3);
      color: #777777;
      padding: 50px 20px;
      font-weight: 300; }
      .login-section .login-block .panel-login .login-container .container-outer {
        display: table;
        position: relative;
        height: 100%;
        width: 280px;
        margin: 0 auto; }
        .login-section .login-block .panel-login .login-container .container-outer .container-inner {
          position: relative;
          display: table-cell;
          vertical-align: middle;
          height: 100%; }
          .login-section .login-block .panel-login .login-container .container-outer .container-inner .logo {
            text-align: center;
            margin-bottom: 40px; }
            .login-section .login-block .panel-login .login-container .container-outer .container-inner .logo .img-rounded {
              width: 200px; }
          .login-section .login-block .panel-login .login-container .container-outer .container-inner .login-form input {
            width: 100%;
            padding: 10px 10px;
            border: 0;
            margin-bottom: 20px;
            font-size: 14px; }
          .login-section .login-block .panel-login .login-container .container-outer .container-inner .login-form button,
          .login-section .login-block .panel-login .login-container .container-outer .container-inner .login-form .submit-login {
            width: 100%;
            border: 0;
            padding: 10px 15px;
            background: #43c3f1;
            color: #ffffff;
            font-size: 14px; }

html,
body {
  height: 100%;
  font-family: 'Oxygen', sans-serif;
  font-weight: 400;
  color: #2a2a2a;
  margin: 0;
  padding: 0;
  position: relative; }

span.active {
  background-color: gray; }

span.active {
  background-color: gray; }

.container {
  max-width: 1200px;
  clear: both;
  float: none;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0; }

.footer {
  padding: 10px 20px;
  margin-top: 5px;
  text-align: center;
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;
  /*background:rgba(0,43,68,.95);*/
  background: #203A45; }

.tagline {
  font-family: 'Oxygen', sans-serif;
  font-size: 24px;
  font-weight: 400; }

radial-jon svg text {
  fill: rgba(0, 0, 0, 0.6);
  font-size: 11px;
  font-weight: 600; }

radial-jon svg {
  margin: 0 auto;
  float: none;
  display: block; }

d3d radial-jon svg text {
  fill: rgba(0, 0, 0, 0.6); }

d3d radial-jon svg .centerText {
  fill: rgba(0, 0, 0, 0.6);
  font-size: 12px !important; }

.personname {
  padding: 15px 20px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 390px;
  display: block;
  height: auto;
  color: #002b44;
  font-size: 21px;
  font-weight: 600;
  font-family: 'Oxygen', sans-serif;
  background: rgba(0, 0, 0, 0.08); }

.person-department {
  padding: 0 20px 15px;
  display: block;
  height: auto;
  color: #002b44;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Oxygen', sans-serif;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.08);
  border-bottom: 2px solid #ffffff; }

.centerText {
  fill: rgba(0, 0, 0, 0.75); }

.accuracy {
  float: right;
  color: #002b44;
  font-weight: 600; }

.tooltip {
  position: absolute;
  z-index: 9999;
  height: auto;
  padding: 6px 10px 8px;
  pointer-events: none;
  border-radius: 0;
  display: none; }

.tooltip.hidden {
  opacity: 0; }

.tooltip {
  font-family: sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff; }

.arc {
  z-index: 1;
  position: relative; }

.legend text {
  font-size: 11px;
  fill: #999999; }

#bubble-chart {
  width: 1200px;
  height: 900px; }

.circename span {
  font-size: 13px;
  color: #999999;
  text-transform: capitalize; }

#menu button {
  background: #dddddd;
  border: 0 solid #000000;
  color: #7a7a7a;
  padding: 5px 10px;
  font-size: 11px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  margin-right: 1px; }

#menu button:focus {
  outline-color: transparent;
  outline-style: none; }

#menu button:hover {
  color: #ffffff;
  background: #203A45; }

.d3dcharts {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1; }

.d3dcharts-menu {
  z-index: 9;
  float: left;
  position: relative;
  margin-left: 30px;
  margin-top: 30px; }

app-header {
  margin: 0;
  padding: 0; }

/** Loader Styles **/
.custom-bubble-icon {
  background: url(1133d8ed4b98e22e1a3a5f490e464c3f.png) no-repeat; }

.new-loader {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #000000; }

/** Loader Styles **/
nav {
  margin-top: 16px; }
  nav a {
    color: #ffffff;
    padding: 8px 4px;
    margin: 4px;
    vertical-align: middle;
    line-height: 1.25;
    text-align: center;
    text-decoration: none;
    border-radius: 2px; }

.main-container {
  width: 100%;
  position: relative;
  z-index: 1; }

.fluid-container {
  width: 100%;
  padding: 20px 15px 15px;
  margin: 0; }
  .fluid-container h2 {
    font-family: 'Oxygen', sans-serif;
    font-size: 18px;
    display: inline-block;
    font-weight: 400;
    margin: 0 0 15px;
    color: #003a57; }
  .fluid-container h3 {
    font-family: 'Oxygen', sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin: 20px 0 15px;
    color: #003a57;
    display: block; }
    .fluid-container h3.starter {
      margin: 0 0 15px; }

.classifier {
  font-size: 18px;
  font-weight: 300; }
  .classifier h2 {
    margin: 0; }
  .classifier .rangeslider span {
    float: left;
    padding-left: 10px;
    font-weight: 400;
    font-size: 14px; }
    .classifier .rangeslider span input {
      margin-top: 3px; }

.message-wrap {
  padding: 0 15px;
  float: left;
  line-height: 32px;
  position: relative; }
  .message-wrap .message {
    position: absolute;
    left: 16px;
    background: #ededed;
    width: auto;
    min-width: 300px; }
    .message-wrap .message span {
      padding-right: 5px;
      font-size: 16px; }
    .message-wrap .message.error {
      color: #003a57; }
    .message-wrap .message.success {
      color: #0B9444; }

.closeToggle {
  float: right;
  color: #ededed;
  background: #003a57;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  position: absolute;
  right: 8px;
  margin-top: -10px; }

.action-button {
  background: #003a57;
  color: #ededed;
  border: 0;
  height: 32px;
  line-height: 32px;
  padding: 0 15px; }

.custom-charts {
  padding: 20px 0; }

.block-header {
  font-size: 18px;
  background: #ededed;
  border-bottom: 2px solid #b3c0c0;
  padding: 8px 15px; }

.block-container {
  padding: 15px 0;
  border: 1px solid #ededed;
  border-top: 0;
  height: auto;
  overflow: hidden;
  min-height: 300px; }

.basic-activity .zero-padding {
  padding: 0; }

.ngx-charts text {
  fill: #7a7a7a; }

.title-block .page-title.block-display {
  display: block;
  width: 100%;
  clear: both; }

.website-list {
  max-height: calc(100vh - 170px);
  overflow-y: auto; }
  .website-list ul {
    padding: 0;
    margin: 0; }
    .website-list ul li {
      list-style: none;
      padding: 8px 15px;
      border-bottom: 1px solid #ededed;
      word-break: break-word;
      width: 420px; }
      .website-list ul li:last-child {
        border: 0; }

.gauge .gauge-tick text {
  fill: #999999 !important; }

.gauge .gauge-tick path {
  stroke: #aaa !important; }

.modal-backdrop.in {
  opacity: 0.75; }

.modal-dialog {
  display: table;
  position: relative;
  height: 100%;
  margin: 0 auto; }
  .modal-dialog .modal-content {
    position: relative;
    vertical-align: middle;
    box-shadow: 0 0 0;
    border: 0; }
    .modal-dialog .modal-content modal-content {
      background: #ffffff;
      height: auto;
      overflow: hidden;
      display: block; }
      .modal-dialog .modal-content modal-content .modal-body {
        font-size: 14px; }
      .modal-dialog .modal-content modal-content .modal-header {
        padding: 10px 15px;
        background: #003a57;
        color: #ffffff; }
        .modal-dialog .modal-content modal-content .modal-header .modal-title {
          font-size: 18px;
          line-height: 30px; }
        .modal-dialog .modal-content modal-content .modal-header .close {
          line-height: 32px;
          font-size: 28px;
          color: #ffffff;
          opacity: 1;
          text-shadow: 0 0 0; }
      .modal-dialog .modal-content modal-content .modal-footer {
        padding: 10px 15px; }
        .modal-dialog .modal-content modal-content .modal-footer .btn {
          border-radius: 0;
          text-transform: uppercase;
          color: #999999; }
        .modal-dialog .modal-content modal-content .modal-footer .btn-primary {
          background: #003a57;
          border-color: #003a57;
          color: #ffffff; }

.loader.loader-inner {
  z-index: 9998;
  width: 100%;
  margin-top: 1px;
  position: fixed; }

.pie-grid-item .label.percent-label {
  font-size: 24px; }

.pie-grid-item text:nth-child(3) {
  bottom: 0; }

.pie-grid-item text:last-child {
  display: none; }

.pie-grid .arc1 {
  opacity: 0.25; }

.noUi-pips-horizontal {
  height: 50px; }

.page-hint {
  color: #80939e;
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 15px; }

.full-width {
  width: 100%; }

.ngx-charts .grid-panel.odd rect {
  fill: rgba(0, 0, 0, 0) !important; }

.custom-modal-content {
  background: #ffffff; }
  .custom-modal-content .modal-header {
    background: #003a57; }
    .custom-modal-content .modal-header .modal-title {
      color: #ffffff;
      font-size: 21px; }
  .custom-modal-content .modal-container {
    padding: 15px; }
    .custom-modal-content .modal-container h4 {
      font-size: 16px;
      line-height: 24px; }
      .custom-modal-content .modal-container h4 span {
        font-weight: 600; }
    .custom-modal-content .modal-container select {
      height: 32px;
      background: #ffffff;
      border: 1px solid #dddddd; }
    .custom-modal-content .modal-container .quick-note {
      padding: 15px 0 0;
      margin: 0;
      font-size: 15px; }

.loader.loader-inner.inverse-loader {
  margin-top: 131px;
  background: #ffffff;
  color: #003a57;
  text-align: center; }
  .loader.loader-inner.inverse-loader .sk-folding-cube .sk-cube:before {
    background: #003a57; }
  .loader.loader-inner.inverse-loader .hub-loader {
    height: calc(100% - 131px); }
  .loader.loader-inner.inverse-loader .loading-text {
    color: #003a57;
    text-align: center; }

.padding-top {
  padding-top: 20px; }

.wizard-content {
  margin-top: 30px;
  clear: both;
  float: none;
  display: block; }
  .wizard-content .wizard-content-bg {
    background: #ffffff;
    box-shadow: 0 0 12px #cccccc;
    min-height: 350px;
    height: 350px; }

.basic-container {
  display: table;
  position: relative;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  width: 100%; }
  .basic-container .basic-content {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    color: #003a57; }

.ng2-tags-container {
  padding-left: 15px; }
  .ng2-tags-container tag {
    background: #cccccc !important;
    padding: 0 10px !important;
    height: 28px !important;
    line-height: 28px !important;
    margin: 5px 5px 0 0 !important; }
  .ng2-tags-container delete-icon svg {
    height: 28px !important; }

.ng2-tag-input {
  border: 0 !important; }
  .ng2-tag-input.ng2-tag-input--focused {
    border: 0 !important; }

.auto-height {
  height: auto;
  overflow: hidden; }

.small-margin-bottom {
  margin-bottom: 15px; }

.group-tags .ng2-tags-container {
  padding: 0; }
  .group-tags .ng2-tags-container tag:first-child {
    display: none; }

.content-footer {
  display: block;
  clear: both;
  height: auto;
  overflow: hidden;
  padding: 30px 50px 0;
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto; }
  .content-footer .wizard-next {
    background: url(40834f62624154bfb5af32b511aca8a6.png) no-repeat;
    width: 42px;
    height: 22px;
    border: 0;
    margin: 0 auto 15px;
    display: block;
    cursor: pointer; }
    .content-footer .wizard-next:focus {
      outline: none; }
  .content-footer .wizard-prev {
    background: url(b03f822d63c732847701ce93d637e3c6.png) no-repeat;
    width: 42px;
    height: 22px;
    border: 0;
    margin: 0 auto 15px;
    display: block;
    cursor: pointer; }
    .content-footer .wizard-prev:focus {
      outline: none; }

.basic-content .form-elements {
  margin: 25px auto;
  max-width: 420px; }
  .basic-content .form-elements label {
    display: block;
    text-align: left; }
  .basic-content .form-elements input {
    height: 32px;
    width: 100%;
    border: 2px solid #ededed;
    float: left;
    display: block;
    padding: 0 8px; }
  .basic-content .form-elements select {
    width: 100%;
    background: #ffffff;
    border: 2px solid #ededed;
    height: 32px;
    border-radius: 0; }
  .basic-content .form-elements ss-multiselect-dropdown .dropdown {
    display: block;
    text-align: left; }
    .basic-content .form-elements ss-multiselect-dropdown .dropdown button {
      width: 100%;
      text-align: left;
      border: 2px solid #ededed;
      border-radius: 0;
      height: 32px; }
      .basic-content .form-elements ss-multiselect-dropdown .dropdown button .caret {
        float: right;
        line-height: 22px;
        margin-top: 7px; }
    .basic-content .form-elements ss-multiselect-dropdown .dropdown .dropdown-menu {
      min-width: 240px; }
      .basic-content .form-elements ss-multiselect-dropdown .dropdown .dropdown-menu .dropdown-item a {
        padding: 5px 10px;
        border-bottom: 1px solid #ededed; }
        .basic-content .form-elements ss-multiselect-dropdown .dropdown .dropdown-menu .dropdown-item a input {
          width: 20px;
          height: 20px;
          margin-top: 2px;
          margin-right: 5px; }
      .basic-content .form-elements ss-multiselect-dropdown .dropdown .dropdown-menu .dropdown-item:last-child a {
        border: 0; }

.wizard-complete {
  border-radius: 50px;
  border: 0;
  padding: 5px 15px; }

.wide-modal {
  max-width: 1000px;
  min-width: 1000px;
  width: 80%; }

.process-container {
  padding: 40px 20px; }
  .process-container .employee-icon {
    background: #ededed;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto 20px; }
    .process-container .employee-icon .attractive {
      line-height: 100px; }
  .process-container .employee-name {
    font-size: 24px;
    margin-bottom: 15px; }
  .process-container .endpoint-count,
  .process-container .department-name,
  .process-container .team-name,
  .process-container .tag-name {
    text-align: left;
    font-size: 16px;
    margin-bottom: 15px; }

pre span {
  white-space: normal; }

.string {
  color: green; }

.number {
  color: darkorange; }

.boolean {
  color: blue; }

.null {
  color: magenta; }

.key {
  color: red; }

.user-company select {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 13px;
  border-radius: 0;
  padding: 5px 10px; }
  .user-company select:focus {
    outline: none; }
  .user-company select option {
    color: #2a2a2a; }

.user-company button {
  background: #43c3f1;
  color: #003a57;
  border: 0;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600; }
  .user-company button:focus {
    outline: none; }

.table-search .label, .active-hour-seven-days .table-search-summary .label {
  color: #2a2a2a;
  font-size: 14px;
  line-height: 30px; }

.table-search select, .active-hour-seven-days .table-search-summary select {
  background: transparent;
  border: 1px solid #dddddd;
  height: 30px; }

.will-update-soon {
  text-align: center;
  padding: 50px 0;
  font-size: 24px; }

.billable-reports .select-report-type {
  background: #ededed;
  padding: 15px;
  margin-bottom: 15px; }
  .billable-reports .select-report-type .label {
    color: #2a2a2a;
    font-size: 16px;
    line-height: 30px; }
  .billable-reports .select-report-type select {
    background: #ffffff;
    border: 1px solid #cccccc;
    height: 30px;
    min-width: 200px; }
    .billable-reports .select-report-type select option {
      text-transform: capitalize; }
  .billable-reports .select-report-type ss-multiselect-dropdown .dropdown-menu li {
    border-bottom: 1px solid #ededed; }
    .billable-reports .select-report-type ss-multiselect-dropdown .dropdown-menu li.divider {
      margin: 5px 0;
      border: 0; }
    .billable-reports .select-report-type ss-multiselect-dropdown .dropdown-menu li.dropdown-item.search {
      padding: 0 5px 5px; }
    .billable-reports .select-report-type ss-multiselect-dropdown .dropdown-menu li a {
      padding: 5px 10px; }
      .billable-reports .select-report-type ss-multiselect-dropdown .dropdown-menu li a input {
        margin: 0 5px 0 0; }

.softwares {
  padding-right: 0; }
  .softwares .software-label {
    text-align: center;
    padding: 25px;
    background: #ededed;
    border-radius: 10px;
    margin-bottom: 25px;
    min-height: 238px; }
    .softwares .software-label .icon {
      font-size: 50px;
      margin-bottom: 5px; }
    .softwares .software-label .text {
      font-size: 21px; }
    .softwares .software-label .release-number {
      font-size: 14px; }
    .softwares .software-label .software-link {
      display: block;
      clear: both;
      float: none; }
      .softwares .software-label .software-link a {
        font-size: 28px;
        padding: 15px 0 5px;
        color: #003a57;
        display: block; }
        .softwares .software-label .software-link a:hover {
          text-decoration: none;
          color: #43c3f1; }

.release-note {
  font-size: 18px;
  text-align: center;
  padding: 25px 0 35px; }

.employee-report form {
  padding: 20px;
  background: #f3f3f3; }
  .employee-report form formly-form {
    column-count: 2;
    display: inline-block;
    width: 100%; }
    .employee-report form formly-form .form-group {
      width: 100%;
      display: inline-block; }

.dashboard-blocks {
  background: #ffffff;
  padding: 15px 20px;
  box-shadow: 3px 3px 6px #ededed;
  border: 1px solid #ededed;
  min-height: 415px;
  margin-bottom: 25px; }
  .dashboard-blocks h4 {
    margin: 0 0 15px;
    text-align: left;
    display: block;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    color: #003a57;
    position: relative;
    padding-bottom: 10px; }
    .dashboard-blocks h4:after {
      height: 3px;
      width: 50px;
      background: #43c3f1;
      position: absolute;
      content: "";
      bottom: 0;
      left: 0; }
  .dashboard-blocks .os-chart {
    display: block;
    min-height: 360px;
    max-height: 360px; }
  .dashboard-blocks .dashboard-block-header {
    margin: 9px 0 15px;
    text-align: left;
    display: block;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold; }
  .dashboard-blocks .badge {
    font-weight: 300; }

#software-upload {
  background: transparent;
  border: 0;
  font-size: 13px;
  line-height: 16px;
  padding: 5px 0; }

#upload-submit {
  padding: 5px 10px;
  background: #003a57;
  border: 0;
  color: #ffffff; }

.margin-bottom-15 {
  margin-bottom: 15px; }

#onoffswitch {
  width: 15px;
  height: 15px;
  min-width: 15px;
  margin-top: 8px;
  margin-right: 5px; }

#onoffswitch-copy {
  width: 15px;
  height: 15px;
  min-width: 15px;
  margin-top: 8px;
  margin-right: 5px; }

#onoffswitch-active {
  width: 15px;
  height: 15px;
  min-width: 15px;
  margin-top: 8px;
  margin-right: 5px; }

.employee-link-chart {
  background: #ffffff;
  padding: 0;
  box-shadow: 3px 3px 6px #ededed;
  border: 1px solid #ededed;
  overflow: hidden;
  min-height: 891px; }
  .employee-link-chart .tooltip {
    color: #2a2a2a; }
  .employee-link-chart .node text {
    pointer-events: none;
    font: 10px sans-serif;
    fill: #999999; }

.vis-rolling-mode-btn {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  background: #43c3f1;
  line-height: 40px; }
  .vis-rolling-mode-btn:before {
    content: "\f021"; }

.loader.loader-inner.inverse-loader.dashboard-loader {
  margin-top: 76px; }

.setup-download .softwares .software-label {
  padding: 15px 25px; }
  .setup-download .softwares .software-label .icon {
    color: #003a57; }
  .setup-download .softwares .software-label .software-link a {
    font-size: 24px;
    padding: 10px 0 0; }

.clients-list {
  margin-bottom: 25px;
  height: auto;
  overflow: hidden; }

.collapsed-menu-header {
  display: none; }
  .collapsed-menu-header span {
    cursor: pointer; }

#reports .status-block {
  padding: 15px;
  background: #ededed;
  height: auto;
  overflow: hidden;
  margin-bottom: 20px; }
  #reports .status-block h1 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 21px;
    font-weight: 600;
    color: #003a57; }
  #reports .status-block h2 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: #003a57; }
  #reports .status-block h3 {
    margin: 0;
    padding: 0;
    font-size: 16px; }
  #reports .status-block .export-report {
    margin-top: 22px; }

#reports .general-information {
  padding: 15px;
  background: #ededed;
  height: auto;
  overflow: hidden; }
  #reports .general-information h2 {
    margin: 0 0 15px;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: #003a57; }
  #reports .general-information .row-odd {
    background: #ffffff;
    padding: 12px 0;
    font-size: 16px;
    border: 2px solid #ffffff; }
  #reports .general-information .row-even {
    background: tranparent;
    padding: 12px 0;
    font-size: 16px;
    border: 2px solid #ffffff; }
  #reports .general-information .employees {
    padding: 0 0 0 5px;
    float: left; }
    #reports .general-information .employees:last-child span {
      display: none; }

#reports .report-overview {
  padding: 15px;
  background: #ededed;
  height: auto;
  overflow: hidden; }
  #reports .report-overview h2 {
    margin: 0 0 15px;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: #003a57; }
  #reports .report-overview .charts-block {
    padding: 25px 50px;
    height: auto;
    overflow: hidden;
    background: #ffffff; }
    #reports .report-overview .charts-block #pie-report {
      width: 300px;
      height: auto;
      float: left;
      overflow: hidden; }
    #reports .report-overview .charts-block #report-legends {
      width: calc(100% - 300px);
      height: auto;
      float: left;
      overflow: hidden; }
  #reports .report-overview .group-chart {
    background: #ffffff;
    padding: 20px; }

#reports .reports-timeline {
  padding: 20px;
  background: #ffffff; }
  #reports .reports-timeline .datatable-header-cell {
    background: #003a57;
    color: #ffffff; }

.activity-filter .range-slider {
  margin: 0;
  max-width: 480px;
  padding: 0 10px;
  min-height: 100px;
  float: left;
  width: 100%; }

.primary-color {
  color: #003a57; }

my-date-picker .mydp {
  max-width: 275px;
  border-color: #dedede; }
  my-date-picker .mydp .selection {
    max-width: 275px; }
  my-date-picker .mydp .selector.selectorarrow {
    border: 0;
    min-width: 275px;
    background: #ffffff; }
    my-date-picker .mydp .selector.selectorarrow .header {
      height: 40px;
      background: #ffffff; }
      my-date-picker .mydp .selector.selectorarrow .header .headerbtncell {
        background: #ffffff; }
        my-date-picker .mydp .selector.selectorarrow .header .headerbtncell .mydpicon {
          font-size: 12px;
          line-height: 24px;
          width: 10px;
          background: #ffffff; }
      my-date-picker .mydp .selector.selectorarrow .header .headerlabelbtn {
        background: #ffffff; }
    my-date-picker .mydp .selector.selectorarrow .caltable th {
      padding: 10px 5px 15px;
      background: #ffffff;
      color: #2a2a2a; }
    my-date-picker .mydp .selector.selectorarrow .caltable td {
      background: #ffffff;
      color: #333333;
      line-height: 24px;
      padding: 5px; }
      my-date-picker .mydp .selector.selectorarrow .caltable td .highlight {
        color: #43c3f1; }
    my-date-picker .mydp .selector.selectorarrow:before {
      border-bottom-color: transparent; }
    my-date-picker .mydp .selector.selectorarrow:after {
      border-bottom-color: #ffffff; }
    my-date-picker .mydp .selector.selectorarrow:focus {
      border: 0; }
      my-date-picker .mydp .selector.selectorarrow:focus:before {
        border-bottom-color: transparent; }

.report-title div:last-child span {
  display: none; }

.report-mail-to {
  display: inline-block;
  padding-right: 6px; }
  .report-mail-to:last-child span {
    display: none; }

#toast-container.toast-top-right {
  top: 85px; }

.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  user-select: initial !important;
  -webkit-user-select: initial !important;
  -moz-user-select: initial !important;
  -ms-user-select: initial !important;
  -o-user-select: initial !important; }

.new-setup-wizard {
  padding: 20px;
  text-align: center; }
  .new-setup-wizard i.attractive {
    font-size: 75px;
    display: block;
    clear: both;
    float: none;
    padding-bottom: 25px;
    margin: 0 auto;
    color: #003a57; }
  .new-setup-wizard h1 {
    margin: 0 0 40px;
    font-size: 24px;
    color: #003a57; }
  .new-setup-wizard h2 {
    font-size: 21px;
    margin-bottom: 10px;
    color: #003a57; }
  .new-setup-wizard h3 {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    font-size: 18px;
    margin: 0 auto 30px;
    text-align: center;
    line-height: 26px;
    color: #003a57; }
  .new-setup-wizard h4 {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    font-size: 14px;
    margin: 0 auto 15px;
    text-align: center;
    line-height: 24px;
    color: #7a7a7a;
    display: block;
    clear: both; }
  .new-setup-wizard .software-section {
    width: 100%;
    max-width: 600px;
    padding: 0 0 30px;
    display: block;
    margin: 0 auto;
    height: auto;
    overflow: hidden; }
    .new-setup-wizard .software-section .icon {
      font-size: 60px;
      margin-bottom: 10px;
      min-height: 80px;
      color: #003a57; }
    .new-setup-wizard .software-section .text {
      font-size: 20px;
      color: #003a57;
      line-height: 21px;
      margin-bottom: 15px;
      font-weight: 600; }
      .new-setup-wizard .software-section .text span {
        font-size: 14px;
        font-weight: 400; }
    .new-setup-wizard .software-section .software-link {
      font-size: 20px; }
      .new-setup-wizard .software-section .software-link span {
        font-size: 18px; }
    .new-setup-wizard .software-section .release-number {
      margin-bottom: 10px; }
  .new-setup-wizard .license-section {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    margin-bottom: 25px;
    text-align: left; }
  .new-setup-wizard .endpoints-installed {
    display: block;
    clear: both;
    height: auto;
    overflow: hidden;
    padding-top: 30px; }
    .new-setup-wizard .endpoints-installed h2 {
      display: block;
      text-align: center; }

.wizard-content .wizard-content-bg.no-shadow {
  box-shadow: 0 0 0; }

.wizard-interface {
  padding: 0;
  margin: 0; }
  .wizard-interface li {
    list-style: none;
    display: block;
    clear: both; }
    .wizard-interface li h2 {
      font-size: 16px;
      float: left; }
    .wizard-interface li .wizard-icons {
      float: left;
      width: 30px; }

.error-message {
  color: #003a57;
  background-color: #cbf1ff;
  border-color: #43c3f1;
  padding: 10px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 3px;
  margin-bottom: 25px; }

.disassociated,
.endpoint-disabled {
  background: #cccccc; }

.dual-list {
  padding: 10px 0;
  height: auto;
  overflow: hidden; }
  .dual-list h4 {
    font-size: 14px; }

.custom-datepicker .selectiongroup {
  width: 100%; }
  .custom-datepicker .selectiongroup input {
    width: calc(100% - 68px);
    min-width: calc(100% - 68px);
    max-width: calc(100% - 68px); }
  .custom-datepicker .selectiongroup .selbtngroup {
    width: 68px;
    float: right; }

ng2-dropdown-menu .ng2-dropdown-menu {
  max-height: 210px !important;
  z-index: 9999 !important; }

.skills-tag .ng2-tags-container {
  padding-left: 0; }

.endpoint-installation .version-label {
  margin: 0; }

.expiry-date .selectiongroup .selbtngroup {
  width: 52px; }

.expiry-date table td button {
  padding: 0; }

.expiry-date table td .headerlabelbtn {
  color: #000000;
  float: none; }

input.upload-text-file-align {
  display: flex;
  padding-top: 7px; }

.profile-container {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 15px;
  margin-right: auto;
  margin-left: auto; }

.cursor-pointer span {
  cursor: pointer; }

input[type=radio] {
  width: 13px !important;
  height: 13px !important; }

.emp-bulk-upload label {
  display: flex;
  align-items: center; }
  .emp-bulk-upload label input {
    margin: 0;
    width: auto; }

.setup-select-endpoint-table datatable-body .datatable-body-row > div {
  display: block !important; }

.error {
  font-size: 13px;
  width: 100%;
  color: red;
  margin-top: 10px; }

.container {
  max-width: 1200px !important;
  clear: both;
  float: none;
  width: 100% !important;
  margin: 0 auto;
  padding: 20px 0 !important; }

.btn-added-source {
  background: #43c3f1;
  color: #ffffff;
  padding: 8px 10px;
  border: 0;
  border-radius: 0; }

.flex-col {
  display: flex;
  align-items: center; }
  .flex-col .custom-label {
    margin-bottom: 0px !important; }
  .flex-col .form-group {
    margin: 0px 10px !important; }
  .flex-col .btn {
    margin: 0px 10px; }
  .flex-col input,
  .flex-col select {
    min-width: 110px;
    height: 32px;
    border: 1px solid #dedede;
    padding: 3px 10px;
    border-radius: 3px;
    background: #ffffff;
    max-width: 275px; }

.add-alerts {
  border: 1px solid #dddddd;
  padding: 15px;
  position: relative;
  display: block;
  margin: 30px 0px;
  border-radius: 5px; }
  .add-alerts > h2 {
    position: absolute;
    text-transform: uppercase;
    top: -10px;
    left: 15px;
    padding: 0px 10px;
    margin-bottom: 0px;
    background: #ededed; }
  .add-alerts h3 {
    font-size: 16px;
    margin-left: 10px; }
  .add-alerts .close-btn {
    padding: 10px;
    cursor: pointer;
    background: transparent;
    border: 0; }
  .add-alerts .close-tab {
    cursor: pointer; }
  .add-alerts .close-tab:hover {
    color: #757575; }

.ui-pnotify-container.brighttheme-notice {
  background: #ffffb3;
  border-radius: 5px;
  color: #000000;
  -webkit-box-shadow: 0px 9px 15px -2px #d6d6d6 !important;
  -moz-box-shadow: 0px 9px 15px -2px #d6d6d6 !important;
  box-shadow: 0px 9px 15px -2px #d6d6d6 !important; }

.main-container .companies-total input {
  min-width: 100%; }

.zero-padding {
  padding: 0 !important; }

.file-checkbox formly-field-multicheckbox {
  display: block; }
  .file-checkbox formly-field-multicheckbox .checkbox {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer; }
    .file-checkbox formly-field-multicheckbox .checkbox:not(:first-child) {
      padding-left: 20px; }

.exclude-checkbox formly-field-radio {
  display: block; }
  .exclude-checkbox formly-field-radio .radio {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer; }
    .exclude-checkbox formly-field-radio .radio:not(:first-child) {
      padding-left: 20px; }

.department-select-label {
  padding: 0 !important; }

.endpoint-exclude-multiselect .dropdown-toggle {
  text-align: left;
  border: 1px solid #dedede;
  min-width: 248px;
  width: 100%; }
  .endpoint-exclude-multiselect .dropdown-toggle span {
    float: right;
    margin-top: 7px; }

.endpointAvailability {
  margin-top: 15px; }
  .endpointAvailability formly-validation-message {
    float: right;
    margin-right: 130px; }

.addFieldButton {
  margin: 15px;
  position: absolute; }

.removeButton {
  padding: 15px;
  margin-bottom: 20px;
  width: 100% !important;
  border-bottom: 1px solid #dddddd; }
  .removeButton button.btn.btn-danger {
    float: right; }

.customFormly .modal-footer {
  border: none; }

.customAlertFormly .modal-footer {
  border-top: 1px solid #dddddd;
  clear: left; }

hr {
  border: none !important; }

.companiesAlerts input#name,
.companiesAlerts input.ng2-tag-input__text-input {
  min-width: inherit !important; }

.setExclusion .ng2-tags-container {
  padding-left: 0 !important; }

.noRecordFound {
  text-align: center;
  padding-top: 40px;
  color: #003a57; }

.add_endpoint a {
  cursor: pointer; }
  .add_endpoint a:hover {
    text-decoration: none; }

.col-md-1 .button {
  background-color: #0B9444;
  border: none;
  color: #ffffff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; }

.lineChartsStyle {
  border: 1px solid #ededed;
  box-shadow: 2px 2px 8px #ededed;
  padding: 0 15px; }
  .lineChartsStyle div h4 {
    color: #003a57;
    padding-left: 5px; }
    .lineChartsStyle div h4 span.text-bold {
      font-weight: 600; }
  .lineChartsStyle div .legend-block {
    padding-top: 30px; }
    .lineChartsStyle div .legend-block .legends {
      float: right; }
      .lineChartsStyle div .legend-block .legends ul li a .legend-circle {
        width: 12px;
        height: 12px;
        border-radius: 0;
        background: #7a7a7a;
        margin-right: 4px;
        display: block;
        float: left;
        line-height: 18px;
        margin-top: 4px; }

.row.doughnutCharts {
  border: 1px solid #ededed;
  box-shadow: 2px 2px 8px #ededed;
  margin: 30px 0 0;
  padding: 0 0 15px 0; }
  .row.doughnutCharts div.headerBar {
    text-align: center;
    padding: 8px; }
    .row.doughnutCharts div.headerBar h5 {
      color: #003a57;
      font-weight: 600; }
  .row.doughnutCharts .chartsContent .col-md-6 .doughnutChartsCanvas {
    border: 1px solid #ededed;
    box-shadow: 2px 2px 8px #ededed;
    padding: 10px 0 30px;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
    text-align: center; }
    .row.doughnutCharts .chartsContent .col-md-6 .doughnutChartsCanvas h5 {
      color: #000000;
      font-weight: 600;
      text-align: center; }
    .row.doughnutCharts .chartsContent .col-md-6 .doughnutChartsCanvas pie-chart-3d svg {
      width: 290px;
      height: 281px; }
    .row.doughnutCharts .chartsContent .col-md-6 .doughnutChartsCanvas div .legend-block {
      top: 70px; }
      .row.doughnutCharts .chartsContent .col-md-6 .doughnutChartsCanvas div .legend-block .legends ul {
        text-align: left; }
        .row.doughnutCharts .chartsContent .col-md-6 .doughnutChartsCanvas div .legend-block .legends ul li {
          display: block; }
  .row.doughnutCharts .chartsContentRadial .col-md-6 .doughnutChartsCanvas {
    border: 1px solid #ededed;
    box-shadow: 2px 2px 8px #ededed;
    padding: 10px 0 30px;
    margin-bottom: 30px;
    text-align: center; }
    .row.doughnutCharts .chartsContentRadial .col-md-6 .doughnutChartsCanvas h5 {
      color: #000000;
      font-weight: 600;
      text-align: center; }

.row.headCountTable {
  margin: 15px 0;
  border: 1px solid #ededed;
  box-shadow: 2px 2px 8px #ededed; }
  .row.headCountTable h5 {
    color: #003a57;
    font-weight: 600;
    text-align: center;
    padding: 5px; }
  .row.headCountTable .datatable {
    margin: 15px; }

div.reports-bar div.monthReports h3 {
  float: right;
  padding-top: 5px; }

div.reports-bar div.monthReports span {
  font-weight: 600; }

.row.horizontal-bar {
  margin: 15px 0;
  border: 1px solid #ededed;
  box-shadow: 2px 2px 8px #ededed;
  padding: 15px; }
  .row.horizontal-bar div h5 {
    color: #003a57;
    font-weight: 600;
    text-align: center; }
  .row.horizontal-bar div .barChart {
    border: 1px solid #ededed;
    box-shadow: 2px 2px 8px #ededed;
    padding: 15px;
    margin-bottom: 30px;
    width: 100%;
    display: inline-block; }
    .row.horizontal-bar div .barChart .ngx-charts-outer.ng-trigger.ng-trigger-animationState {
      width: 100% !important;
      text-align: center; }
      .row.horizontal-bar div .barChart .ngx-charts-outer.ng-trigger.ng-trigger-animationState .ngx-charts {
        float: none; }
    .row.horizontal-bar div .barChart div h5 {
      color: #000000; }

.main-container .department-checkbox {
  margin: 0; }
  .main-container .department-checkbox input[type="checkbox"] {
    margin-top: 10px;
    height: auto; }

.no-data-display p {
  padding: 20px;
  text-align: center; }

.dept-month-report .action-button {
  background: #003a57;
  color: #ededed;
  border: 0;
  height: 32px;
  border-radius: 0 !important;
  line-height: 32px;
  padding: 0 15px; }
  .dept-month-report .action-button:hover {
    color: #ededed; }
  .dept-month-report .action-button:focus {
    color: #ffffff; }

.monthlReportTable .datatable-body .datatable-body-cell-label a {
  margin-left: 15px; }

.monthlReportTable .reportDownloadBtn {
  cursor: pointer; }

.nodata-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center; }

.priority-multiselect .dropdown-toggle:hover {
  background-color: #004261; }

.priority-multiselect input {
  min-width: 0; }

.password-policy {
  margin-top: 30px; }
  .password-policy .circle-style {
    margin-right: 10px; }

.ngx-datatable.employee-table-style .datatable-body .progress-linear .container {
  background-color: transparent; }

.custom-placeholder-color ::placeholder {
  color: #cccccc;
  /* Firefox */ }

.custom-placeholder-color :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccccc; }

.custom-placeholder-color ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccccc; }

.department-form .dept-textbox {
  min-width: 270px; }

.web-circler-chart {
  float: left; }
  .web-circler-chart circle-chart path {
    cursor: pointer; }

.glyphicon-refresh-animate {
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear; }

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg); }
  to {
    transform: scale(1) rotate(360deg); } }
